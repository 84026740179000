export default {
  smsCode: '/basic/sms/sms-code', // 获取验证码
  pwdSmsCode: '/elebuys/basic/sms/sms/sms-code', // 获取验证码 - 忘记密码专用
  loginPageInfo: '/elebuys/right/login-page/query', // 获取登录页配置信息
  tabStatistical: '/elebuys/bpm/approve/tabStatistical', // 审批中心-tab数字角标接口
  industryList: '/elebuys/member/company/industry-list', // 行业列表

  remindGetListByPage: '/elebuys/blyc/remind/getListByPage', // 获取消息列表
  remindCountNum: '/elebuys/blyc/remind/countNum', // 统计消息数量
  remindRead: '/elebuys/blyc/remind/read', // 消息设为已读
  getGuessList: '/elebuys/support-center/article/guess-you-want-ask', // 猜你想问
  csPermission: '/ll/cs/permission', // 客服权限
  completedUrl: '/datacenter/datapanel/completed-url',
  queryInfo: '/bestgift/distributor-login-page/query',

  memberOrganization: '/elebuys/member/company/managed-organization', // 查询管理的组织架构树（部门和员工一起）
  isDeptHead: '/elebuys/organization/employee/is-dept-head',
};
